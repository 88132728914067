.Login{
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    
}
.login-containere{
    margin-top: 30px;
}

.login-containert{
  width: 100% !important;
  display: flex;
  flex-direction: row;
}

.logu{
  height: 5rem;
 
  margin-top: -20px;
  display: block;
  margin-left: auto ;
  margin-right: auto; 
}

.form {
    /* border: 3px solid #f1f1f1; */
    border: none;
  }
  
  /* Full-width inputs */
  input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  /* Set a style for all buttons */
  .button8 {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;

    background-color: #310e68;
    background-image: linear-gradient(316deg, #310e68 0%, #5f0f40 74%);
  }
  .button2 {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  .i{
    text-align: center;
  }
  
  /* Add a hover effect for buttons */
  .button:hover {
    opacity: 0.8;
  }
  
  /* Extra style for the cancel button (red) */
  
  
  /* Center the avatar image inside this container */
  .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
  }
  
  /* Avatar image */
  img.avatar {
    width: 40%;
    border-radius: 50%;
  }
  
  /* Add padding to containers */
  .container {
    padding: 16px;
  }
  
  /* The "Forgot password" text */
  span.psw {
    float: right;
    padding-top: 16px;
  }
  
  /* Change styles for span and cancel button on extra small screens */
  @media screen and (max-width: 300px) {
    span.psw {
      display: block;
      float: none;
    }
    .cancelbtn {
      width: 100%;
    }
  }

