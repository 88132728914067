.btt {
    
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    background-color: #310e68;
    background-image: linear-gradient(316deg, #310e68 0%, #5f0f40 74%);


  }
  
  .btt:hover {
    opacity: 0.8;
  }

  .login-container{
    width: 100% !important;
    display: flex;
    flex-direction: row;
  }
  .lb{
      text-align: center;
  }

  .lbw{
      float: right;
  }

  
  .logo{
    height: 5rem;
   
    margin-top: -20px;
    display: block;
    margin-left: auto ;
    margin-right: auto; 
  }
  .po{
      margin-top: -20px;
      margin-bottom: -25px;
  }
  .o{
      font-size: 24px;
  }
/* .colsd{
    background-color: #bcbddc;
background-image: linear-gradient(316deg, #bcbddc 0%, #807dba 74%);
margin-bottom: 2%;

} */
.l{
    padding-bottom: 6px;
}
/* .g{
    color: white;
    font-size: 20px;
} */

.f{
  color: red;
}


.dot {
  height: 30px;
  width: 50px;
  /* background-color: #bbb; */
  border-radius: 50%;
  display: inline-block;
  margin-top: -3px;
  margin-bottom: -25px;
  margin-right: -40px;
  
  float:right;


  /* background-color: #310e68;
  background-image: linear-gradient(316deg, #310e68 0%, #5f0f40 74%); */
}