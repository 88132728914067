.login-containerr,.r{
    width: 100%;
   
}
.y{
    width: 100%;
}
.ho{
    margin-top: 2%;
}
.logoo{
    height: 5rem;
    margin-top: -20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}
.poo{
    margin-top: 20px;
    margin-bottom: -25px;
}

.circle{
   
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    top:0;
    right: 0;
    bottom: 0;
    margin-top: 100px;
    
  }
  .haeda{
    color: brown;
  }